import React, { Component } from 'react'

class About extends Component 
{
  render() 
  {
    const returnValue = (
    		<div className="container">
              <div className="row">
                <div className="col-sm">
                  <br /><p />
                  <br />	
				  <p>I am a computer engineer who develops free and open source applications for the community. I believe that the way to a better world is decentralization, therefore I strongly support decentralized projects like cryptocurrencies. Those used as a store of value like Bitcoin have a limited supply, which protects people from the continuous inflation caused by the printing of money by central banks. Others like Ethereum allow you to develop decentralized apps that are beyond the control of a single authority.<br /><br /></p>
		          <p>E-mail: <a href="mailto:z3studio@protonmail.com">z3studio@protonmail.com</a></p>
		          <br />
		          <p>Recommended links:</p>
		  		  <br />
		  		  <p><a href="https://bitcoin.org" target="blank">- Bitcoin: digital gold</a></p>
	  		      <p><a href="https://www.ethereum.org" target="blank">- Ethereum: decentralized applications</a></p>
  		          <p><a href="https://curve.fi" target="blank">- Curve: decentralized exchange</a></p>
  		          <p><a href="https://www.convexfinance.com" target="blank">- Convex: boosted Curve
staking</a></p>
  		          <p><a href="https://velodrome.finance" target="blank">- Velodrome Finance: decentralized exchange</a></p>
  		          <p><a href="https://makerdao.com" target="blank">- MakerDAO: decentralized autonomous organization</a></p>  		          
  		          <p><a href="https://www.olympusdao.finance" target="blank">- Olympus DAO: decentralized reserve currency protocol</a></p>
                  <br /><p />
                  <br /><p />	
                </div>
              </div>
		    </div>)

     return returnValue;
   }
}

export default About;
