import React from 'react';  
import ReactDOM from 'react-dom';  
import {HashRouter as Router, Route, NavLink, Switch} from 'react-router-dom'   
import 'bootstrap/dist/css/bootstrap.css'

import Home from './components/Home';
import About from './components/About';

import './z3studio.css';  

const Notfound = () => <div><br/><p/><h1>Invalid URL</h1><br/><p/></div>

const routing = (  
    <Router>  
        <div className="row navigation_bar">
        
           <div className="col-sm">
			   <NavLink to="/" exact>Home</NavLink>  
		   </div>
		   
		   <div className="col-sm">
			   <NavLink to="/about" exact>About</NavLink>  
		   </div>
		   
	    </div>
        <div>  
            <Switch>  
                <Route exact path="/" component={Home} />  
                <Route exact path="/about" component={About} />  
                <Route component={Notfound} />  
            </Switch>  
        </div>  
    </Router> 
)  

ReactDOM.render(routing, document.getElementById('root'));
