import React, { Component } from 'react'

class Home extends Component 
{
  render() 
  {
    const returnValue = (
    	    <div className="container">
              <div className="row">
                <div className="col-sm">
                  <br /><p />
                  <br /><p />
			      <h3>Z3 Studio</h3>
              	  <br /><p />	
		          <p className="centered_text">Free and open source software</p>
              	  <br /><p />
              	  <br /><p />
              	  <br /><p />
			    </div>
		      </div>
		    </div>)
     
     return returnValue;
   }
}

export default Home;
